import { Box, Button, Stack, Snackbar, Alert, Avatar, Typography,  } from '@mui/material';
import {FormattedMessage} from "react-intl";
import TournamentsService from "../services/tournaments/TournamentsService"
import React, { useState, useEffect } from 'react';
import { SuccessMessage } from '../constants';
import {useRecoilState,} from 'recoil';
import {ThemeAtom} from "../user/atoms/UserInfo"
import { DataGrid, gridClasses  } from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';
import {timeTillIfLowerThenDay, ruLongMonthTime, timeTillIsZero} from "../utils/DateUtils"
import {linkToGameByID} from "../utils/Games"
import {useNavigate,} from "react-router-dom"



var tournamentID = 0


const TournamentPlayers = (props) => {
    const navigate = useNavigate();

    const [joinedShow, setJoinedShow] = useState(false);

    const [players, setPlayers] = useState([]);
    const [meState, setMeState] = useState({});
    const [signInNow, setSignInNow] = useState(false);
    const [timeTill, setTimeTill] = useState("");
    const [signInCheckTimeout, setSignInCheckTimeout] = useState(0)
    const [timeTillInterval, setTimeTillInterval] = useState(0)
    const [canStartGame, setCanStartGame] = useState(false);

	const [innerTheme, _] = useRecoilState(ThemeAtom);



    const requestState = function() {
        TournamentsService.getPlayersState(props.tournament.ID).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                setMeState(response.data.RequesterState)
                setPlayers(response.data.PlayersState)
            }
        })
    }

    const tillTimeChange = function() {
        if (!props.tournament) {
            return
        }
        if (!meState.SignedIn) {
            return
        }
        if (timeTillIsZero(props.tournament.TimeStart)) {
            setCanStartGame(true)
        } else {
            setCanStartGame(false)
        }
        setTimeTill(timeTillIfLowerThenDay(props.tournament.TimeStart))
    }

    const joinToTournament = ()=>{
        TournamentsService.joinTournament(props.tournament.ID).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                setJoinedShow(true)
                requestState()
            }
        })
    }

    const commitParticipation = ()=>{
        TournamentsService.signIn(props.tournament.ID).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                setMeState({...meState, SignedIn: true})
            }
        })
    }
    useEffect(() => {
        if (meState.SignedIn) {
            clearInterval(timeTillInterval)
            var intervalTill = setInterval(tillTimeChange, 1100)
            setTimeTillInterval(intervalTill)
        }
    }, [meState])

    useEffect(() => {
        if (!props.tournament) {
            return
        }
        requestState()
        tournamentID = props.tournament.ID

        if (props.tournament && timeTillIsZero(props.tournament.TimeStart)) {
            setCanStartGame(true)
        } else {
            setCanStartGame(false)
        }

        let checkForSignIn = function() {
            if (!props.tournament) {
                return
            }
            if (tournamentID != props.tournament.ID) {
                return
            }

            var now = new Date()
            var start = new Date(props.tournament.SignInStart)
            var end = new Date(props.tournament.SignInEnds)
            if (now > start && now < end && !signInNow) {
                setSignInNow(true)
            } else {
                var newSignInCheckTimeout = setTimeout(checkForSignIn, 1100)
                setSignInCheckTimeout(newSignInCheckTimeout)
                if (now < start || now > end) {
                    setSignInNow(false)
                }
            }
        }

        clearTimeout(signInCheckTimeout)
        var newSignInCheckTimeout = setTimeout(checkForSignIn, 1100)
        setSignInCheckTimeout(newSignInCheckTimeout)
    }, [props.tournament])

    const goPlay = () =>{
        navigate(linkToGameByID(props.tournament.GameIDs[0]))
    }
    return (
        <div>
            {meState.TournamentID == undefined ?
                <div></div>
                : meState.TournamentID == 0 ?
                    <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" spacing={2} paddingX={5} paddingY={1}>
                       {canStartGame ? <div style={{padding: "30px"}}><FormattedMessage id="tournament_already_started_or_completed" /></div> : <><div><FormattedMessage id="tournament_will_start" /> {props.tournament && ruLongMonthTime(props.tournament.TimeStart)}</div>						
                        <Button variant="contained" size="large" color="success" onClick={joinToTournament}>
                            {<FormattedMessage id="join" />}
                        </Button></>}
                    </Stack>
                : 
                <div style={{padding: "20px"}}>
                    {!meState.SignedIn && signInNow && <Button variant="contained" size="large" color="success" onClick={commitParticipation}>
                        <FormattedMessage id="commit_participation" />
                    </Button>}
                    {meState.SignedIn && !props.tournament.HasBrackets && !props.tournament.Ended && <Button disabled variant="contained" size="large" color="success">
                        {canStartGame ? <FormattedMessage id="start_game" /> : <><FormattedMessage id="time_left" />&nbsp;{timeTill}</>}
                    </Button>}
                    {false && meState.SignedIn && props.tournament.HasBrackets && <Button onClick={goPlay} variant="contained" size="large" color="success">
                        <FormattedMessage id="play" />
                    </Button>}
                    <br/>
                    {!meState.SignedIn && <div style={{padding: "20px"}}>
                        <FormattedMessage id="you_registered_to_tournament" /><br/>
                        {!props.tournament.Ended && !canStartGame && <><FormattedMessage id="to_participate_sign_in" /> {ruLongMonthTime(props.tournament.SignInStart)} <FormattedMessage id="till" /> {ruLongMonthTime(props.tournament.SignInEnds)} <FormattedMessage id="here_on_tournament_page" /></>}<br/>
                        {canStartGame ? props.tournament.Ended ? <FormattedMessage id="tournament_already_ended" /> : <FormattedMessage id="tournament_already_started" /> : <><FormattedMessage id="tournament_will_start" /> {ruLongMonthTime(props.tournament.TimeStart)}</>}
                    </div>}
                    {meState.SignedIn && <div style={{padding: "20px"}}>
                        {props.tournament.Ended ? <FormattedMessage id="you_participated_in_tournament" /> : <FormattedMessage id="you_participating_in_tournament" />}<br/>
                        {!props.tournament.Ended && props.tournament.HasBrackets && <Typography variant='h6'><FormattedMessage style={{padding: "10px"}} id="start_game_for_partcipation" /></Typography>}<br/>
                        {canStartGame ? props.tournament.Ended ? <FormattedMessage id="tournament_already_ended" /> : <FormattedMessage id="tournament_already_started" /> : <><FormattedMessage id="tournament_will_start" /> {ruLongMonthTime(props.tournament.TimeStart)}</>}
                    </div>}
                </div>
            }

            {players.length > 0 ? <StripedDataGrid
                columns={[
                    {
                        field: 'username',
                        width: 235,
                        headerName: <FormattedMessage id="players" />,
                        description:"",
                        renderCell: (params) => {
                            return (<div style={{display:"table", overflow: "hidden", width: "400px"}}>
                                <div style={{display: "table-cell", verticalAlign: "middle", width: "45px", height: "45px"}} >
                                <Avatar
                                    
                                    key={"ava"+params.row.id}
                                    alt={params.row.Nickname}
                                    src={params.row.IconUrl == "" ? "/themes/"+innerTheme.theme+"/imgs/icons/user.png" : "/mcs_static/shared/avatars/"+params.row.IconUrl+"?dopf23f21"}
                                    sx={{ width: 45, height: 45 }}
                                /></div>
                                <div style={{display: "table-cell", verticalAlign: "middle", align: "left", paddingLeft: "12px"}}><a href={"/user/"+params.row.Nickname} style={{color: "inherit", textDecoration: "none"}} target="_blank">{params.row.Nickname}</a></div>
                            </div>)}, 
                    },
                    {
                        field: 'status',
                        width: 235,
                        headerName: <FormattedMessage id="status" />,
                        renderCell: (params) => {
                           return params.row.SignedIn ? <FormattedMessage id="signed_in_tournament" /> : <FormattedMessage id="joined_tournament" />
                        },
                    },
                ]}
                rows={players}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                  }
                  pageSizeOptions={[]}
                  disableRowSelectionOnClick 
                  hideFooterPagination={true}
                  hideFooter={true}
                  disableColumnMenu={true}
            /> :
            <div>
                <FormattedMessage id="nobody_participating_for_now" />
            </div>}

            <Snackbar open={joinedShow}
                onClose={() => { setJoinedShow(false)}}
                autoHideDuration={6000} 
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
                        sx={{marginBottom: "100px"}} 
                        key={"joined_to_tournament"}>
          <Alert severity="info" sx={{ width: '100%' }} key={"joined_to_tournament"}
            action={null}         
          >
            <FormattedMessage id="joined_successfully" />
          </Alert>
			</Snackbar>
        </div>
    )
}

export default TournamentPlayers;

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => { 
    return ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.mode == "light" ? theme.palette.grey[200] : theme.palette.grey[800],
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
})
}
);