import { Box, Grid } from '@mui/material';
import {FormattedMessage} from "react-intl";









const About = () => {

    return (
        <Box
        sx={{
            minHeight: '100vh',
            bgcolor: 'background.default',
            color: 'text.primary',
            justifyContent: 'center',
            p: "40px",
        }}>
            <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={4} sm={4} md={3}>
                        <img src="about/photo.png" width="300px"></img>
                    </Grid>
                    <Grid item xs={4} sm={4} md={9}>
                        <div style={{textAlign: "justify", fontSize: "1.3rem", whiteSpace: 'pre-line'}}><FormattedMessage id="about_start" /></div>
                    </Grid>
                    <Grid item xs={4} sm={8} md={12}>
                    </Grid>
            </Grid>
        </Box>
    )
}

// <div style={{textAlign: "justify", marginLeft: "30px"}}><FormattedMessage id="about_myself" /></div>


export default About;