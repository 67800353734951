import { Stack, Typography, Divider, Grid, useTheme } from "@mui/material";
import {FormattedMessage} from "react-intl";
import React, { useState, useEffect } from 'react';
import {useNavigate} from "react-router-dom"






const LeaderboardEntry = (props) => {
    const navigate = useNavigate();

    const theme = useTheme();
    const goToCard = ()=>{
        navigate('/user/'+props.nickname)
        //window.open('/user/'+props.nickname,'_blank')
    }
    return (
        <Stack direction="column" alignItems="center" style={{cursor: "pointer"}} onClick={goToCard}>
            <Stack direction="row" spacing={3} alignItems="center" style={{width: "100%"}} >
                <Grid container columns={{ xs: 25, sm: 25, md: 25 }} spacing={{ xs: 4, sm: 2, md: 2}} direction="row" justifyContent="flex-start" style={props.myplace == props.place ? {backgroundColor: theme.palette.mode == "light" ? "#D0EBFC" : "#3f2f9c", maring: "0px", padding: "0px",} : null}>
                        <Grid item xs={1} sm={1} md={1}>                    
                            <Typography variant="h4">{props.place}</Typography>
                        </Grid>
                        <Grid item xs={15} sm={8} md={4}  style={{ display: "flex", alignItems: "center"}}>
                            <Typography variant="h5" style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>{props.nickname}</Typography>
                        </Grid>
                        <Grid item xs={7} sm={15} md={19}>
                            <Stack direction="column" spacing={1} alignItems="left" style={{width: "100%", marginTop: "-10px"}}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Typography variant="h8">{props.score}</Typography>
                                </Stack>
                                <div style={{height: "12px", backgroundColor: "#3B78DB", width: props.percents+"%"}}></div>
                            </Stack>
                        </Grid> 
                                                                                    
                </Grid>
            </Stack>
            {props.divider ? <Divider style={{width: "100%"}}/> : null}
        </Stack>
    )
}

export default LeaderboardEntry;
