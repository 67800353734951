import {Divider, Box} from '@mui/material';
import {FormattedMessage} from "react-intl";








const LegalInformation = ()=>{
    return (
        <Box
            sx={{
                minHeight: '100vh',
                bgcolor: 'background.default',
                color: 'text.primary',
                p: 3,
            }}
        >
            <div><FormattedMessage id="footer_legal_information" /></div>
            <div><a href="/user_agreement" target="_blank"><FormattedMessage id="user_agreement"/> </a></div>
            <div><a href="/privacy_policy" target="_blank"><FormattedMessage id="privacy_policy"/></a></div>
        </Box>
    )
}

export default LegalInformation;
