import {
    useRecoilState,
  } from 'recoil';
import { Avatar, IconButton, Stack, Typography, Snackbar, Alert } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, {useState} from 'react';
import './user-info.scss';
import { UserInfoAtom, UserAvatarSuffix } from '../../../atoms/UserInfo';
import { ShowFileTooBig } from '../../../atoms/Show';
import UserService from '../../../../services/user/UserService';
import { SuccessMessage, MaxAvatarSize } from '../../../../constants';
import {FormattedMessage} from "react-intl";
import {makeString} from '../../../../utils/common';

const UserInfo = ({ avatar, nickname, email, akey }) => {
	const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);
    const [selectedFile, setSelectedFile] = useState(null);
	const [showTooBig, setShowTooBig] = useRecoilState(ShowFileTooBig);
	const [userAvatarSuffix, setUserAvatarSuffix] = useRecoilState(UserAvatarSuffix);


    const handleFileSelect = (event) => {
        console.log(event.target.files[0])
        if (event.target.files[0].size > MaxAvatarSize){
            setShowTooBig({show: true})
        } else {
            setSelectedFile(event.target.files[0])
			const formData = new FormData();
			formData.append("avatarFile", event.target.files[0]);
			UserService.uploadAvatar(formData).then(()=>{
				UserService.getUserInfo().then(response=>{
					if (response.data.Success == SuccessMessage) {
						setUserAvatarSuffix(makeString(5))
						setUserInfo(response.data.UserInfo)
					}
				})
			})
         }
    }

	return (
		<Stack direction="row" spacing={2} alignItems="center">
			<div className="user-info__avatar">
				<Avatar
					key={akey}
					alt={nickname}
					src={avatar}
					sx={{ width: 64, height: 64 }}
				/>

				<IconButton aria-label="edit" variant="contained" size="small" component="label"  className="user-info__avatar-edit" style={{position: 'absolute', backgroundColor: '#eeeeee'}}>
					<EditIcon fontSize="inherit"/>
					<input
    					type="file"
    					hidden
						onChange={handleFileSelect} accept="image/*"
  					/>
				</IconButton>
			</div>

			<Stack>
				<Typography variant="h6">{nickname}</Typography>

				<Typography variant="body1">{email}</Typography>
			</Stack>

		</Stack>
	)
};

export default UserInfo;