import { Button, IconButton } from '@mui/material';
import {FormattedMessage} from "react-intl";
import { isMobile } from '../utils/common';
import {useRecoilState,} from 'recoil';
import {UserInfoAtom} from "../user/atoms/UserInfo"
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';







const GameTab = (props) => {
    const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);

    const closeMobileGame = ()=>{
        location.href = "/games"
    }
    const toSubscription = ()=>{
        location.href = "/subscription"
    }
    const handleFullScreen = ()=>{
        if(document.fullscreenElement){ 
            document.exitFullscreen()
         } else { 
            document.body.requestFullscreen()
        } 
    }
    return (
        <div style={{height: "30px", color: "white"}}>
            <IconButton
                size="large"
                aria-label="FullScreen"
                aria-controls="menu-appbar"
                onClick={handleFullScreen}
                color="inherit"
                style={{position:"absolute", top: "-8px", left: !isMobile && userInfo && userInfo.AccountType == "standart" ? "160px" : "5px",}}
            >
                {document.fullscreenElement ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
            {!isMobile && userInfo && userInfo.AccountType == "standart" && <Button size="small" variant="outlined" style={{position:"absolute", top: "5px", left: "5px", zIndex: 999999, display: 'inline-block', padding:0, minHeight: 0, minWidth: 0,}} onClick={toSubscription}><FormattedMessage id="turnoff_ad"/></Button>}
            <Button size="small" variant="outlined" style={{position:"absolute", top: "5px", right: "5px", zIndex: 999999, display: 'inline-block', padding:0, minHeight: 0, minWidth: 0,}} onClick={closeMobileGame}><FormattedMessage id="exit_from_game"/></Button>
        </div>
    )
}


export default GameTab;