import {domainName} from "../../environment";
import AuthService from "../../services/auth/AuthService";
import {SuccessMessage, EndSaltLength} from "../../constants";
import {makeString} from "../../utils/common"
import React, { useEffect } from 'react';

import {
    useRecoilState,
  } from 'recoil';
  import {Authorized,} from '../atoms/Auth'


function AuthCheck() {
    const [authorized, setAuthorized] = useRecoilState(Authorized);

    useEffect(() => {
        let authToken = localStorage.getItem('AuthToken');
        let refreshToken = localStorage.getItem('RefreshToken');
        if (!authorized.Authorized && authToken !== null && refreshToken !== null && authToken !== "" && refreshToken !== "") {
            authToken = authToken.slice(0, -EndSaltLength);
            refreshToken = refreshToken.slice(0, -EndSaltLength);
            setAuthorized({Authorized: true})
        } else if (!authorized.Authorized) {
        }
        if (window.location.pathname == "/") {
            ym(98579665,'reachGoal','EndLoadingMainPage')
            window.react_started = "YES"
        }
    }, []);

    return (
        <div></div>
    )
}

export default AuthCheck;
