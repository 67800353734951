import {
    atom,
} from 'recoil';


export const GodotEngine = atom<any>({
    key: 'GodotEngine',
    default: null,
})


export const GodotInstance = atom<any>({
    key: 'GodotInstance',
    default: null,
})

export const HasGodotInstance = atom<any>({
    key: 'HasGodotInstance',
    default: false,
})

export const GodotLoadingState = atom({
    key: 'GodotLoadingState',
    default: {
        mode: "indeterminate",
        initializing: true,
        msg: "",
        percent: 0,
      },
})