import * as React from "react"

import { FunctionComponent, useEffect, useRef, useState } from "react"

import { useLoading } from "./AsyncLoading.tsx"
import {GodotInstance, GodotEngine, HasGodotInstance} from "./atoms.tsx"
import {useRecoilState,} from 'recoil';

export type ReactEngineProps = {
  engine: Engine
  canvasWidth?: number
  canvasHeight?: number
  params?: any
  resize?: boolean
  godotConfig: any
  loadStarted: any
  totalSize?: number
  scaleFactor: any
}

function toFailure(err) {
  var msg = err.message || err
  console.error(msg)
  console.log("ASASAS toFailure error stack", err.stack)
  return { msg, mode: "notice", initialized: true }
}

const ReactCanvas: FunctionComponent<ReactEngineProps> = ({
  engine,
  canvasWidth = 480,
  canvasHeight = 300,
  godotConfig,
  loadStarted,
  totalSize,
  scaleFactor
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  //const [instance, setInstance] = useState<any>()
  const [loadingState, changeLoadingState] = useLoading()

  // useEffect(() => {
  //   if (engine.isWebGLAvailable()) {
  //     changeLoadingState({ mode: "indeterminate" })
  //     setInstance(new engine(godotConfig))
  //   } else {
  //     changeLoadingState(toFailure("WebGL not available"))
  //   }
  // }, [engine,])

  // useEffect(() => {
  //   if (instance) {
  //     instance
  //       .startGame({
	// 				'onProgress': function (current, total) {
  //           total = totalSize
	// 					if (total > 0) {
	// 						//statusProgressInner.style.width = current/total * 100 + '%';
	// 						//setStatusMode('progress');
  //             changeLoadingState({ mode: "progress", percent: current / total * 100 })
	// 						if (current === total) {
	// 							// wait for progress bar animation
	// 							setTimeout(() => {
  //                 changeLoadingState({ mode: "indeterminate" })
	// 							}, 500);
	// 						}
	// 					} else {
  //             changeLoadingState({ mode: "indeterminate" })
	// 					}
	// 				},
	// 			})
  //       .then(() => {
  //         changeLoadingState({ mode: "hidden", initialized: true })
  //         if (canvasRef.current) {
  //           var loadingFinished:any = new Date()
  //           console.log("ASASAS TIMETOLOAD = ", loadingFinished - loadStarted)
  //           canvasRef.current.style.position = "relative";
  //           canvasRef.current.style.width = canvasWidth+"px";
  //           canvasRef.current.style.height = canvasHeight+"px";
  //           console.log("SIZES", canvasWidth, canvasHeight)
  //         }
  //       })
  //       .catch(err => changeLoadingState(toFailure(err)))

  //     // instance.setProgressFunc((current, total) => {
  //     //   if (total > 0) {
  //     //     changeLoadingState({ mode: "progress", percent: current / total })
  //     //   } else {
  //     //     changeLoadingState({ mode: "indeterminate" })
  //     //   }
  //     // })
  //   }
  // }, [instance, godotConfig, changeLoadingState])

  useEffect(()=>{
    if (canvasRef.current) {
        //var loadingFinished:any = new Date()
       // console.log("ASASAS TIMETOLOAD = ", loadingFinished - loadStarted)
        canvasRef.current.style.position = "absolute";
        //canvasRef.current.style.width = canvasWidth+"px";
        //canvasRef.current.style.height = canvasHeight+"px";
        canvasRef.current.style.transform = "translate(-50%, -50%) scale("+scaleFactor+")"
       // console.log("ASASASSIZES", canvasWidth, canvasHeight)
    }
  }, [canvasRef, scaleFactor,])

  // useEffect(() => {
  //   if (instance) {
  //     instance.setCanvas(canvasRef.current)
  //   }
  // }, [instance, canvasRef.current])

  return (
    <canvas
      ref={canvasRef}
      id="canvas"
      style={{ display: loadingState.initializing ? "hidden" : "block", zIndex: 888 }}
    >
      HTML5 canvas appears to be unsupported in the current browser.
      <br />
      Please try updating or use a different browser.
    </canvas>
  )
}

export default ReactCanvas