
import ReactGodot from "../react-godot/GodotReact.tsx"
import {useParams, useNavigate} from "react-router-dom"
import { isMobile } from '../utils/common';
import {UserInfoAtom} from "../user/atoms/UserInfo"
import {useRecoilState,} from 'recoil';
import {useWindowSize} from "../utils/libs/WindowSize.tsx"
import React, { useState, useEffect } from 'react';
import GameTab from "./GameTab.jsx"
import {GodotLoadingState} from "../react-godot/atoms.tsx"

const RightSideWidth = 254
const TabHeight = 30
var loadingStart = null
var loadingFinished = null

const use_gz = true

function toFailure(err) {
    var msg = err.message || err
    console.error(msg)
    console.log("ASASAS toFailure error stack", err.stack)
    return { msg, mode: "notice", initialized: true }
  }

const ShowGame = (props) => {
    let { gameName } = useParams();

    const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);
    const [windowWidth, windowHeight] = useWindowSize();

    const [leftSideSize, setLeftSideSize] = useState({width: 1024, height: 600})
    const [rightSideSize, setRightSideSize] = useState({width: RightSideWidth, height: 600})
    const [canvasSize, setCanvasSize] = useState({width: 500, height: 200})
    const [tabSize,  setTabSize] = useState({width: 1024, height: TabHeight})
    const [engine, setEngine] = useState(null)
    const [instance, setInstance] = useState(null)
    const [scaleFactor, setScaleFactor] = useState(1)
    const [loadingState, setLoadingState] = useRecoilState(GodotLoadingState);

    if (props.current) {
        localStorage.setItem(gameName+"_opponent", "NicknameTest");
    } else {
        localStorage.removeItem(gameName+"_opponent");
    }

    var GODOT_CONFIG = {}
    var game_width = 1324
    var game_height = 600
    var totalSize = 14551460
    switch(gameName) {
        case "SlyBalabama":
            if (use_gz) {
                GODOT_CONFIG = {"args":[],"canvasResizePolicy": 1,"executable":"/games_content/SlyBalabama/SlyBalabama","experimentalVK":false,"fileSizes":{"SlyBalabama.mcs.gz":5218976,"SlyBalabama.gz":13790961},"focusCanvas":true,"gdnativeLibs":[],
                "mainPack": "/games_content/SlyBalabama/SlyBalabama.mcs.gz",
                "extraPacks": []
            }; // "/games_content/SlyBalabama/SlyBalabama2.mcs.gz" // SlyBalabama.mcs.gz
            } else {
                GODOT_CONFIG = {"args":[],"canvasResizePolicy": 1,"executable":"/games_content/SlyBalabama/SlyBalabama","experimentalVK":false,"fileSizes":{"SlyBalabama.pck":5218976,"SlyBalabama":13790961},"focusCanvas":true,"gdnativeLibs":[],
                    "mainPack": "/games_content/SlyBalabama/SlyBalabama.pck",
                    "extraPacks": []
                };
            }
 
            game_width = 1324
            game_height = 600
            break;
        case "OneVSOneInSpace":
            if (use_gz) {
                GODOT_CONFIG = {"args":[],"canvasResizePolicy": 1, "executable":"/games_content/OneVSOneInSpace/OneVSOneInSpace","experimentalVK":false,"fileSizes":{"OneVSOneInSpace.mcs.gz":4295248,"OneVSOneInSpace.gz":13790961},"focusCanvas":true,"gdnativeLibs":[]};
            } else {
                GODOT_CONFIG = {"args":[],"canvasResizePolicy": 1, "executable":"/games_content/OneVSOneInSpace/OneVSOneInSpace","experimentalVK":false,"fileSizes":{"OneVSOneInSpace.pck":4295248,"OneVSOneInSpace":13790961},"focusCanvas":true,"gdnativeLibs":[]};
            }
            game_width = 1324
            game_height = 600
            break;
        case "BlocksChallenge":        
            GODOT_CONFIG = GODOT_CONFIG = {"args":[],"canvasResizePolicy": 1,"executable":"/games_content/BlocksChallenge/BlocksChallenge","experimentalVK":false,"fileSizes":{"BlocksChallenge.mcs.gz":652832,"BlocksChallenge.gz":13790961},"focusCanvas":true,"gdnativeLibs":[], 
            "mainPack": "/games_content/BlocksChallenge/BlocksChallenge.mcs.gz",
            "extraPacks": []};
            game_width = 1324
            game_height = 600
            break;
    }
    var loadingBanner = "/games_content/LoadingBanners/"+gameName+".png"

    useEffect(() => {
        let scriptUrl = "/games_content/mcs_start_script_not_gz.js"
        if (engine != null) {

          return
        }
        loadingStart = new Date()
        const script = document.createElement("script")
        switch (gameName) {
            case "SlyBalabama":
                ym(98579665,'reachGoal','SlyBalabamaStartLoad')
                break
            case "BlocksChallenge":
                ym(98579665,'reachGoal','BlocksChallengeStartLoad')
                break
        }
    
        if (use_gz) { // true
          scriptUrl = "/games_content/mcs_start_script_gz.js"
        }
    
        script.src = scriptUrl
        script.async = true
        script.onload = () => {
            const scope = window
            setEngine(() => scope.Engine)
        }
    
        document.body.appendChild(script)
    
        return () => {
          document.body.removeChild(script)
        }
      }, [])

      useEffect(() => {
        if (engine && engine.isWebGLAvailable()) {
          setLoadingState({...loadingState, mode: "indeterminate" })
          setInstance(new engine(GODOT_CONFIG))
        } else {
          console.error("... no web gl")
        }
      }, [engine,])
    
      useEffect(() => {
        if (instance) {
          instance
            .startGame({
                        'onProgress': function (current, total) {
                total = totalSize
                            if (total > 0) {
                                //statusProgressInner.style.width = current/total * 100 + '%';
                                //setStatusMode('progress');
                                setLoadingState({ ...loadingState, mode: "progress", percent: current / total * 100 })
                                if (current === total) {
                                    // wait for progress bar animation
                                    setTimeout(() => {
                                        setLoadingState({ ...loadingState, mode: "indeterminate" })
                                    }, 500);
                                }
                            } else {
                                setLoadingState({ ...loadingState, mode: "indeterminate" })
                            }
                        },
                    })
            .then(() => {
                setLoadingState({ ...loadingState,mode: "hidden", initialized: true })
                loadingFinished = new Date()
                console.log("ASASAS TIMETOLOAD = ", loadingFinished - loadingStart)
                switch (gameName) {
                    case "SlyBalabama":
                        ym(98579665,'reachGoal','SlyBalabamaEndLoad')
                        break
                    case "BlocksChallenge":
                        ym(98579665,'reachGoal','BlocksChallengeEndLoad')
                        break
                }
            })
            .catch(err => console.log("Error: ", err))
        }
      }, [instance])


    useEffect(()=>{
        if (!isMobile && userInfo && userInfo.AccountType == "standart") {
            if (windowWidth > 900) {
                setRightSideSize({width: RightSideWidth, height: windowHeight})
                setLeftSideSize({width: windowWidth - RightSideWidth, height: windowHeight})
                setScaleFactor((windowWidth - RightSideWidth)/game_width)
                setCanvasSize({width: windowWidth - RightSideWidth, height: ((windowWidth - RightSideWidth) * game_height / game_width) - TabHeight})
                setTabSize({width: windowWidth - RightSideWidth, height: TabHeight})

                window.yaContextCb.push(() => {
                    Ya.Context.AdvManager.render({
                        "blockId": "R-A-11933797-1",
                        "renderTo": "yandex_rtb_R-A-11933797-1"
                    })
                })
            } else {
                setRightSideSize({width: 0, height: 0})
                setLeftSideSize({width: windowWidth, height: windowHeight})
                setCanvasSize({width: windowWidth, height: ((windowWidth) * game_height / game_width) - TabHeight})
                setScaleFactor((windowWidth)/game_width)
                setTabSize({width: windowWidth, height: TabHeight})
            }
        } else {
            setRightSideSize({width: 0, height: 0})
            setLeftSideSize({width: windowWidth, height: windowHeight})
            setCanvasSize({width: windowWidth, height: (windowWidth) * game_height / game_width})
            if (isMobile) {
                var possible_height = (windowWidth)/game_width * game_height
                if (possible_height < windowHeight) {
                    setScaleFactor((windowWidth)/game_width)
                } else {
                    setScaleFactor((windowHeight)/game_height)
                }
            } else {
                setScaleFactor((windowWidth)/game_width)
            }
            setTabSize({width: windowWidth, height: TabHeight})
        }
    }, [userInfo, isMobile, windowWidth, windowHeight])

    return(
        <div key={"godotplaydiv"} style={{position: "related", width: "100%", height: "100%", top: 0, left: 0, backgroundColor: "#000000", zIndex: 99999}}>
            <div key={"leftpart"} style={{position: "absolute", left: "0px", top: "0px", width: leftSideSize.width, height: leftSideSize.height, backgroundColor: "#000000"}}>
                <GameTab style={{position: "absolute", left: "0px", top: "0px", width: tabSize.width, height: tabSize.height}}></GameTab>
                <ReactGodot key={"godotplay"} godotConfig={GODOT_CONFIG} canvasWidth={canvasSize.width} canvasHeight={canvasSize.height} scaleFactor={scaleFactor} engine={engine} totalSize={14551460} resize={true} is_gz={true}  loadingBanner={loadingBanner}/>
            </div>
            {rightSideSize.width > 0 && <div key={"rightpart"} style={{position: "absolute", right: "0px", top: "0px", width: rightSideSize.width, height: rightSideSize.height}} id="yandex_rtb_R-A-11933797-1">

            </div>}
        </div>
    )
}

export default ShowGame;