import {
    atom, 
} from 'recoil';


export const OpenAuth = atom({
    key: 'OpenAuth',
    default: false,
})

export const ShowRegisterGiftSubscription = atom({
    key: 'ShowRegisterGiftSubscription',
    default: {Open: false},
})