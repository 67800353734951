import { Box, Stack, Avatar, Typography, Divider, Grid, Button, Modal } from '@mui/material';
import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from "react-router-dom";
import UserService from '../../services/user/UserService';
import {SuccessMessage, MiniChatWidth} from "../../constants"
import {FormattedMessage} from 'react-intl'
import {domainNameAPI, protocolAPI, apiPort} from "../../environment"
import {ThemeAtom,} from "../atoms/UserInfo"
import {useRecoilState,} from 'recoil';
import {makeString} from "../../utils/common"
import {Authorized,} from "../atoms/Auth"

const UserCard = (props)=>{
    const [innerTheme, _] = useRecoilState(ThemeAtom);
	const [authorized, setAuthorized] = useRecoilState(Authorized);

    const [avatar, setAvatar] = useState("");
    let [userPersonal, setUserPersonal] = useState({Surname: "", Name: "", About: "", Phone: "", Birthday: "", Country: "", Region: "", City: ""});    
    let [userLeaderboards, setUserLeaderboards] = useState([])
    let [userTournamentAchievements, setUserTournamentAchievements] = useState([])
    let [cardUserInfo, setCardUserInfo] = useState({})
	let { nickname } = useParams();
    const [showReportuserModal, setShowReportuserModal] = useState(false);

	const navigate = useNavigate();

    useEffect(() => {
        UserService.getUserInfoNickname(nickname).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                UserService.showUserInfo(response.data.UserInfo.UserID).then((response)=>{
                    if (response.data.Success == SuccessMessage) {
                        setUserPersonal(response.data.UserPersonal)
                    }
                })
                UserService.getUserLeaderboards(response.data.UserInfo.UserID).then((response)=>{
                    if (response.data.Success == SuccessMessage) {
                        setUserLeaderboards(response.data.Leaderboards)
                    }
                })
                UserService.getUserTournamentsAchievements(response.data.UserInfo.UserID).then((response)=>{
                    if (response.data.Success == SuccessMessage) {
                        setUserTournamentAchievements(response.data.TournamentAchievements)
                    }
                })
                
                let apiUrl = protocolAPI+domainNameAPI+apiPort
                let avaSrc = "/themes/"+innerTheme.theme+"/imgs/icons/user.png";
                if (response.data.UserInfo && response.data.UserInfo != null && response.data.UserInfo.IconUrl !== "") {
                  avaSrc=apiUrl+"/mcs_static/shared/avatars/"+response.data.UserInfo.IconUrl+"?dop"+makeString(5)
                }
                setAvatar(avaSrc)
                setCardUserInfo({UserID: response.data.UserInfo.UserID, IconUrl: response.data.UserInfo.IconUrl})
            }
        })
    }, [])

    let fromString = ""
    if (userPersonal.Country !== "" || userPersonal.Region !== "" || userPersonal.City !== "") {
        fromString = <span><FormattedMessage id="from"/> {userPersonal.City} {userPersonal.Region} {userPersonal.Country}</span>
    }
    let telString = ""
    if (userPersonal.Phone != "") {
        telString = <span><FormattedMessage id="tel"/> {userPersonal.Phone}</span>
    }
    let dohaveAnything = false;
    if (userPersonal.Name !== "" || userPersonal.Surname !== "" || userPersonal.About !== "" || userPersonal.Country !== "" || userPersonal.Region !== "" || userPersonal.City !== "" || userPersonal.Phone != "") {
        dohaveAnything = true
    }

    let mobile = false
    if (window.matchMedia("(max-width: 899px)").matches) {
        mobile = true
    }
    return (
        <Box
            sx={{
                minHeight: '100vh',
                bgcolor: 'background.default',
                color: 'text.primary',
            }}
        >
            <Stack direction="column" spacing={3} py={3} px={5}>
                <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12, lg: 16}} alignItems="flex-start" justifyContent="flex-start">
                    <Grid item xs={12} sm={12} md={4} lg={4} alignItems="flex-start" justifyContent="flex-start">
                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
                            <Avatar
                                    key={"akey"}
                                    alt={nickname}
                                    src={avatar}
                                    sx={{ width: 64, height: 64 }}
                                />
                            <Typography variant="h6">{nickname}</Typography>
                        </Stack>
                    </Grid>          
                </Grid>
                <Typography variant="h6" align="left" >
                    {userPersonal.Surname} {userPersonal.Name}<br/>
                    <span style={{width: "100%", whiteSpace: "initial", wordWrap: "break-word"}}>{userPersonal.About}</span><br/>
                    {fromString}<br/>
                    {telString}<br/>
                    {dohaveAnything ? <span></span> : <FormattedMessage id="havenoanything"/>}
                </Typography>
                <Divider/>
                <Typography variant="h4" align="left" >
                    <FormattedMessage id="rating_points"/>:
                </Typography>
                {userLeaderboards && userLeaderboards.map((item, index) => (
                    <>
                        <Typography variant="h5" align="left" >
                           (<FormattedMessage id={item.GameName}/>) {item.LeaderboardName}: {item.Score}
                        </Typography>
                    </>
                ))}
                <Divider/> 
                <Typography variant="h4" align="left" >
                    <FormattedMessage id="tournament_achievements_count"/>:
                </Typography> 
                {userTournamentAchievements && userTournamentAchievements.map((item, index) => (
                    <>
                        <Typography variant="h5" align="left" >
                           <FormattedMessage id={item.TournamentName}/>: {item.Trophies}
                        </Typography>
                    </>
                ))}
                {userTournamentAchievements && userTournamentAchievements.length == 0 &&
                    <>
                        <Typography variant="h5" align="left" >
                            <FormattedMessage id="has_no_trophies" />
                        </Typography>
                    </>
                }
                            
            </Stack>                      
        </Box>
    )
}

export default UserCard;
